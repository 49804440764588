<template>
  <div
    v-if="!showSuccessMessageCase"
    class="d-flex justify-content-center">
    <div
      class="cta text-white text-center p-3"
      :class="{ 'bg-orchid': fedexPreferred, 'bg-plum': !fedexPreferred }">
      <p>{{ message }}</p>
      <BaseButton
        :to="button.to"
        variant="secondary"
        :text="button.text"
        @click="button.action"/>
    </div>
  </div>
  <div
    v-if="showSuccessMessageCase"
    class="d-flex justify-content-center">
    <div
      class="cta bg-plum text-white text-center p-3">
      Success! 🎉 You're now using FedEx for returns
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import { useFedExOptInStore } from '@/stores/fedExOptIn'
import { useReturnCarrier } from '@/composables/returnCarrier'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  source: {
    type: String,
    default: 'cta'
  }
})
const { showSuccessMessageCase } = storeToRefs(useFedExOptInStore())
const { optIntoFedEx } = useFedExOptInStore()
const { fedexPreferred } = useReturnCarrier()
const toReturnItems = computed(() => store.state.closet.toReturnItems.length > 0)

const message = computed(() => {
  if (fedexPreferred.value) {
    return 'Time for your free pickup with FedEx.'
  } else if (toReturnItems.value || props.source === 'case') {
    return 'Get free scheduled pickups with FedEx on your next case.'
  } else {
    return 'Get free scheduled pickups with FedEx.'
  }
})

const button = computed(() => {
  return {
    text: fedexPreferred.value ? 'Schedule Home Pickup' : 'Opt into FedEx',
    to: fedexPreferred.value ? { name: 'account', query: { section: 'shipping-returns' } } : null,
    action: fedexPreferred.value ? null : () => optIntoFedEx(props.source)
  }
})

</script>
<style lang="css" scoped>
.cta {
  border-radius: 4px;
  width: 90%;
}
</style>
